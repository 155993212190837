import * as React from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import "./ContactDetails.scss";
const ContactDetails = () => {
  return (
    <>
      <div className="contact-address">
        <h3>office details</h3>
        <div className="address-wrapper">
          <div className="address-files">
            <h4>Address</h4>
            <address>
              Office P305-306, <br></br>The Onyx Tower 2,
              <br></br>The Greens, <br></br>Dubai <br></br> <a href="https://goo.gl/maps/VmEw95yCy1DYYapV6" target={`_blank`}> View on Map</a>
            </address>
          </div>
          <div className="contact-field">
            <h4>Telephone / Email</h4>
            <a href="tel:+97143806683" className="tel">
            +971 4 380 6683
            </a>
            <Link to="/contact/" className="tel">
              info@mcconeproperties.com
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactDetails;
